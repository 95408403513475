body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@font-face {
	font-family: 'NanumGothic';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothic.eot);
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothic.eot?#iefix)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothic.woff)
			format('woff'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothic.ttf)
			format('truetype');
}

@font-face {
	font-family: 'NanumGothicBold';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicBold.otf)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicBold.ttf)
			format('truetype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicBold.woff)
			format('woff');
}

@font-face {
	font-family: 'NanumGothicExtraBold';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicExtraBold.otf)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicExtraBold.ttf)
			format('truetype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicExtraBold.woff)
			format('woff');
}

@font-face {
	font-family: 'NanumGothicLight';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicLight.otf)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicLight.ttf)
			format('truetype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumGothic/NanumGothicLight.woff)
			format('woff');
}
@font-face {
	font-family: 'NanumMyeongjo';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjo.eot);
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjo.eot?#iefix)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjo.woff)
			format('woff'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjo.ttf)
			format('truetype');
}

@font-face {
	font-family: 'NanumMyeongjoBold';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoBold.eot);
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoBold.eot?#iefix)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoBold.woff)
			format('woff'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoBold.ttf)
			format('truetype');
}

@font-face {
	font-family: 'NanumMyeongjoExtraBold';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoExtraBold.eot);
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoExtraBold.eot?#iefix)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoExtraBold.woff)
			format('woff'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumMyeongjo/NanumMyeongjoExtraBold.ttf)
			format('truetype');
}
@font-face {
	font-family: 'NanumPen';
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumBrush/NanumPen.eot);
	src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumBrush/NanumPen.eot?#iefix)
			format('embedded-opentype'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumBrush/NanumPen.woff) format('woff'),
		url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumBrush/NanumPen.ttf)
			format('truetype');
}

@font-face {
	font-family: 'HakgyoansimChilpanjiugaeTTF-B';
	src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-5@1.0/HakgyoansimChilpanjiugaeTTF-B.woff2')
		format('woff2');
	font-weight: 700;
	font-style: normal;
}
